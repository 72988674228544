
#measure-group-select-measure {
  .measure-card {
    display: inline-block;

    .custom-remove-btn {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
