#data-entry-form-creation {

  .variable-sort-item {
    display: inline-block;
    padding: 6px;
    margin: 5px;
    background: #777777;
    color: #ffffff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    span {
      margin: 3px;
    }
  }
}
