.business_unit_screen_fix_table {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 450px;
  overflow-y: scroll;

  thead {
    flex: 0 0 auto;
  }

  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

}
