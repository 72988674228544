#data-entry-page {

  .frequency-selector {
    border-bottom: 3px solid #eeeeee;

    .frequency-selector-item {
      display: inline-block;
      background: #eeeeee;
      padding: 12px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin: 10px 10px 0px 10px;
      border-bottom: 3px solid #eeeeee;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.selected {
        border-bottom: 3px solid #dfdfdf;
        background: #dfdfdf;
        font-weight: 700;
      }
    }
  }

  .data-entry-details {
    padding: 10px;
    border: 1px solid #cdcdcd;
    margin-bottom: 20px;
    border-radius: 5px;
    background: #eeeeee;

    .data-entry-details-item {
      font-size: 15px;

      .badge-secondary {
        color: #23282c;
        background-color: #eeeeee;
      }
    }
  }

  .data-form-entry-wrapper {
    margin: 0;
    padding-top: 8px;

    .data-form-entry-item {
      background: #efefef;
      padding: 0 0 5px 0;
      margin-bottom: 15px;

      .data-entry-item-header {
        background: #3c6652;
        margin: 0;
        padding: 5px;
        margin-bottom: 15px;

        .custom-button {
          margin-top: 0;
          margin-left: 10px;
        }
      }

      .down-wastage-btn {
        margin-top: 30px;
        width: 36px;
      }

      .hot-key:focus {
        outline: #4CB6D7 solid 1px;
      }
    }

    .group-wrapper {
      background: #dfdfdf;
      margin: 15px;
      padding: 10px 0;
    }
  }

  .form-entry-row {
    margin-bottom: 15px;
    margin-left: 25px;
    margin-right: 25px;

    .form-item {
      min-width: 250px;
      margin-right: 15px;

      .item-downtime:first-child {
        margin-left: 25px !important;
      }
    }

    .row-button {
      height: 36px;
      margin-right: 10px;
    }

    .item-downtime {
      //border-bottom: 1px solid #8c160a;
    }


    .item-wastage {
      //border-bottom: 1px solid #d3b300;
    }
  }

}
