#dashboard {

}

.dashboard_screen_fix_table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  text-align: center;

  thead {
    flex: 0 0 auto;
  }

  tbody {
    flex: 1 1 auto;
    display: block;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

}

.table-responsive {
  height: 100%;
  overflow: hidden;
}

.dashboard_date_picker {
  border-color: #e4e5e6;

  :hover {
    background: #ffffff;
  }
}

.singleTab_dropDown {
  background-color: #f0f3f5;
  border: none;
  margin-bottom: -10px;
  color: inherit;
}

.downtimeWastagePercentageFilter {
  width: 300px;
  margin: 10px auto;
}
