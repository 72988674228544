#projection-page {

  .data-entry-details {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;

    .data-entry-details-item {
      font-size: 15px;

      .badge-secondary {
        color: #23282c;
        background-color: #eeeeee;
      }
    }
  }

  .data-form-entry-wrapper {
    margin: 15px;
    padding-top: 8px;

    .data-form-entry-item {
      background: #efefef;
      padding: 15px 0 5px 0;
      margin-bottom: 15px;
    }
  }

  .form-entry-row {
    margin-bottom: 15px;
    margin-left: 25px;
    .form-item {
      min-width: 100px;
      margin-right: 15px;
    }
    .row-button {
      height: 36px;
      margin-right: 10px;
    }
  }

}
