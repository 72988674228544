@import "../mixins";

#report_generate_screen {

  .measure-box-header {
    padding: 8px;
    background: #558399;
    margin: 5px 0;
    color: #FFF;
    font-weight: bold;

    span {
      display: inline-block;
      padding: 5px;
      margin: 0 5px;
    }

    .measure-type {
      background-color: rgb(77, 189, 116);
      color: white;
      border-radius: 5px;
      padding: 2px 5px;
    }
  }

  .measure-box-body {
    margin: 0 0 5px 0;
    padding: 10px;
    background: #eaeaea;
  }

  .data-entry-form-wrapper {
    margin: 10px 5px;
    padding: 0 0 10px 0;
    background: #dddddd;

    .data-entry-form-details {

      background: #06779c;
      padding: 10px 5px;
      color: #ffffff;

      span {
        display: inline-block;
        padding: 2px;
        margin: 0 5px;
        font-weight: bold;

        &.frequency-show {
          background-color: rgb(100, 154, 189);
          color: white;
          border-radius: 5px;
          padding: 2px 5px;
        }
      }
    }

    .data-entry-form-measure-select {
      padding: 10px;
    }
  }


}

.custom_select_generate_report_include {
  flex: 1 1 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 1px;
  padding: 16px;
  padding-right: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  color: rgb(127, 140, 141);
  border-color: rgb(127, 140, 141);
  display: inline-flex;
  width: 100%;

  &:hover {
    color: rgb(32, 168, 216);
    border-color: rgb(32, 168, 216);
  }

  &:checked {
    color: rgb(32, 168, 216);
  }
}

.custom_select_box_underline {
  flex: 1 1 0;
  //border-width: 1px;
  border-bottom-style: solid;
  border-radius: 1px;
  padding: 16px;
  padding-right: 8px;
  margin-bottom: 0;
  margin-right: 8px;
  color: rgb(127, 140, 141);
  border-color: rgb(127, 140, 141);
  display: inline-flex;
  width: 100%;

  &:hover {
    color: rgb(32, 168, 216);
    border-color: rgb(32, 168, 216);
  }

  &:checked {
    color: rgb(32, 168, 216);
  }
}
