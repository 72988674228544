// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";
@import "~react-loader-spinner/dist/loader/css/react-spinner-loader.css";
@import "common";
@import "pages/role";
@import "pages/user";
@import "pages/business_unit";
@import "pages/measute_group";
@import "pages/sub_business_unit";
@import "pages/process";
@import "pages/advanced_measure";
@import "pages/widget_add_edit";
@import "pages/dashboard";
@import "pages/report_generate_screen";
@import "pages/waste";
@import "pages/data_entry_creation";
@import "pages/data_entry";
@import "pages/projection";
//TODO remove
@import "pages/projections";
@import "vendors/react_autosuggest_theme";

.badge {
  font-size: 100%;
}

html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 220px;
}

.sidebar-fixed .sidebar {
  width: 220px;
}

.sidebar .nav {
  width: 220px;
}

.sidebar .sidebar-nav {
  width: 220px;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -220px;
}

.required {
  &:after {
    font-family: FontAwesome;
    content: "\f0a3";
    color: #ff8d00;
    padding-left: 4px;
    font-size: .6em;
    position: relative;
    top: -5px;
  }
}

.custom-modal-w95p {
  .modal-dialog {
    max-width: 95% !important;
  }
}

.custom-modal-w80h50 {
  .modal-dialog {
    max-width: 80% !important;
    max-height: 350px !important;
  }

  .modal-body {
    min-height: 350px;
  }
}

.custom-modal-height-500 {
  height: 500px !important;
}

.custom-modal-w65h350 {
  .modal-dialog {
    max-width: 50% !important;
  }

  .modal-body {
    min-height: 350px;
  }
}

.custom-modal-w75p {
  .modal-dialog {
    max-width: 75% !important;
  }
}

.custom-modal-w40p {
  .modal-dialog {
    max-width: 40% !important;
  }
}


.custom-modal-h500 {
  .modal-dialog {
    height: 500px !important;
  }
}

.inactive-row {
  background-color: #f8dddd !important;
}

.round-border-box {
  border: 1px solid #c6c6c6;
  @include border-radius(5px);
  margin: 20px 0;
  padding: 5px 15px;

  h5 {
    position: relative;
    top: -16px;
    background: #FFFFFF;
    display: inline-block;
    padding: 0 10px;
  }

  .box-content {
    padding: 0 5px 5px 5px;
  }
}

.stepper-common {
  margin: 20px 0;
  background: #f3f3f3;
  @include border-radius(5px);
  padding: 15px;
}

.selectable-box {
  border: 1px solid #dfdfdf;
  @include border-radius(5px);

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background: #e2e2e2;
  }
}

.user-types-add-edit-widget {
  padding: 10px;
  background: #ffffff;
}

.user-types-add-edit-report {
  padding: 10px;
  background: #ffffff;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  width: 1.50rem;
  height: 1.50rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

.measure-expression {
  border: 1px solid #4dbd74;
  border-radius: 10px;
  color: #fff;
  background-color: #6c757d;
}

.push-notifier-viewer {
  max-height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
}

.custom-modal-w80h70 {
  .modal-dialog {
    max-width: 80% !important;
    max-height: 500px !important;
  }

  .modal-body {
    min-height: 500px;
  }
}

.custom-modal-w40h40 {
  .modal-dialog {
    max-width: 60% !important;
    max-height: 200px !important;
  }

  .modal-body {
    min-height: 200px;
  }
}

.custom-modal-w40h45 {
  .modal-dialog {
    max-width: 50% !important;
    max-height: 200px !important;
  }

  .modal-body {
    min-height: 300px;
  }
}

.push-notifier-scroll::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.push-notifier-scroll::-webkit-scrollbar-track {
  background-color: transparent
}

.push-notifier-scroll::-webkit-scrollbar-thumb {
  background-color: #6b6b71;
  border-radius: 10px;
  border: 1px
}

.col-7-12 {
  -ms-flex: 0 0 14.285714%;
  flex: 0 0 14.285714%;
  max-width: 14.285714%;
}

.col-5-12 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control:disabled {
  background-color: #e4e7ea !important;
}

.color-warn {
  color: #d3875a;
}

.very-small-btn {
  padding: 0.3rem 0.5rem;
  font-size: 0.875rem;
  line-height: 0;
}

.login-logo {
  width: 85%;
  margin-bottom: 15px;
}

.fake-hour {
  cursor: pointer;
}

.custom-small-btn-with-text {
  padding: 2px 10px;
  line-height: 1;
  position: relative;
  top: -3px;
}

.table-responsive {
  overflow-y: auto;
}

.m-right-5-p {
  margin-right: 5%;
}

.w-35-p {
  width: 35%;
}

.w-40-p {
  width: 40%;
}

.w-55-p {
  width: 55%;
}

.w-60-p {
  width: 60%;
}

.custom-inline-input-wrapper-div {
  vertical-align: top;
}
