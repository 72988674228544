@import "../mixins";

#widget-add-edit-screen {

  .widget-type {
    display: inline-block;
    height: 100px;
    width: 170px;
    position: relative;
    margin-right: 25px;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      color: #0d5c8c;
    }

    .fa {
      font-size: 60px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .small-icon-custom {
      font-size: 20px;
      margin: 0;
      position: absolute;
      top: 20%;
      left: 90%;
      -ms-transform: translate(-20%, -90%);
      transform: translate(-20%, -90%);
    }

    &.selected {
      color: #0d5c8c;
      padding: 6px;
    }
  }

  .template-process-info {
    padding: 10px 0;
    margin: 5px 0;

    .item {
      display: inline-block;
      padding: 10px;
      border: 1px solid #e4e4e4;
      background: #ededed;
      @include border-radius(5px);
    }
  }

  .measure-rule-view {
    padding: 10px;
    margin: 5px 0;
    background: #dadada;
  }

  .checked-relative-color {
    position: relative;
    top: 1px;
  }

  .checked-relative-check {
    position: relative;
    top: -10px;
  }

  .widget-svg:hover {
    fill: #0d5c8c;
  }

  .measure-box-header {
    padding: 8px;
    background: #558399;
    margin: 5px 0;
    color: #FFF;
    font-weight: bold;

    span {
      display: inline-block;
      padding: 5px;
      margin: 0 5px;
    }

    .measure-type {
      background-color: rgb(77, 189, 116);
      color: white;
      border-radius: 5px;
      padding: 2px 5px;
    }
  }

  .measure-box-body {
    margin: 0 0 5px 0;
    padding: 10px;
    background: #eaeaea;
  }

  .data-entry-form-wrapper {
    margin: 10px 5px;
    padding: 0 0 10px 0;
    background: #dddddd;

    .data-entry-form-details {

      background: #06779c;
      padding: 10px 5px;
      color: #ffffff;

      span {
        display: inline-block;
        padding: 2px;
        margin: 0 5px;
        font-weight: bold;

        &.frequency-show {
          background-color: rgb(100, 154, 189);
          color: white;
          border-radius: 5px;
          padding: 2px 5px;
        }
      }
    }

    .data-entry-form-measure-select {
      padding: 10px;
    }
  }
}
