@import "../mixins";

#advanced_measure {
  margin: 20px 0;
  background: #f3f3f3;
  @include border-radius(5px);
  padding: 15px;

  .single-selected-measure-box {
    border: 1px solid #c6c6c6;
  }

  .single-operator-box {
    border: 1px solid #c6c6c6;
    background: #ffffff;

    &:hover {
      cursor: pointer;
    }
  }

  .measure-result-box {

    &.success {
      background: green;
    }

    &.failed {
      background: red;
    }

    @include border-radius(5px);
    padding: 8px;
    display: inline-block;
    color: #ffffff;
    margin: 5px;
    margin-bottom: 1rem;

  }
}


