
#projections-add-edit {

  .single-measure {

    padding: 4px 15px;
    background: #f0f0f0;
    margin: 8px 0;

    .projection-measure-header {
      background: #f0f0f0;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .badge-secondary, .badge-info {
      font-weight: 400;
    }
  }

}
