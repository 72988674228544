
#waste-add-edit {
  .react-autosuggest__input {
    width: 100%;
    border-color: #e4e7ea;
  }

  .inactive-sub-category {
    background-color: gainsboro;
  }
}




