
#user-role-scroll-section, #user-company-scroll-section, #user-location-scroll-section {

  border: 1px solid #c6c6c6;
  @include border-radius(5px);
  margin: 5px 0;
  margin-bottom: 15px;
  padding: 10px;

  max-height: 450px;
  overflow: auto;

  .item-selection {
    display: inline-block;
    min-width: 200px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .check-box-label-user{
    width: 200px;
  }
}
