@import "../mixins";

.privilege-scroll-section {
  max-height: 450px;
  overflow: auto;
}

.privilege-category-name {
  color: #4f4f4f;
  display: inline;
  padding: 5px;
  position: relative;
  top: -20px;
  background: #FFFFFF;
}

.privilege-main-category-section {
  border: 1px solid #c6c6c6;
  @include border-radius(5px);
  margin: 20px 0;

  .privilege-category-section {
    .privilege-checkbox {
      display: inline-block;
      min-width: 200px;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.check-box-label-role{
  width: 200px;
}

