#supervisor-group-select-supervisor {
  .supervisor-card {
    display: inline-block;

    .custom-remove-btn {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .supervisor-states-ACT {

  }

  .supervisor-states-INA {
    background-color: mistyrose;
  }
}
