
.p-5-p {
  padding: 5px;
}

.p-top-5-p {
  padding-top: 5px;
}


.m-right-5px {
  margin-right: 5px;
}

.m-bottom-10p {
  margin-bottom: 10px;
}

.m-top-28p-md {
  margin-top: 28px;
}

@media (max-width: 767px) {
  .m-top-28p-md {
    margin-top: 0;
  }
}

.btn-clear {
  color: #5a5a5a;
  background-color: #fff;
  border-color: #5a5a5a;

  &:hover {
    color: #3c3c3c;
    background-color: #fff;
    border-color: #3c3c3c;
  }
}

.search-result-table {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}

.no-results {
  margin: 15px;
}

.inline-block {
  display: inline-block;
}

.main-pagination {
  div {
    display: inline-block;
  }
}
